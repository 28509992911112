const React = require('react');
const { node, bool, shape, string } = require('prop-types');
const { loadable } = require('nordic/lazy');
const RecommendationsIconFactory = require('./icon-factory');
const RecommendationsCarouselByType = require('@recommendations-fe/carousel-by-type');

const PolycardContext = loadable(() => import('../polycard/polycardContext'));

const Polycard = loadable(() => import('../polycard/polycard'));

Polycard.preload();

const RecommendationsCardVertical = loadable(() => import('./recomendations-card'), {
  resolveComponent: components => components.RecommendationsCardVertical,
});

RecommendationsCardVertical.preload();

const RecommendationsCardHorizontalTitleFirst = loadable(() => import('./recomendations-card'), {
  resolveComponent: components => components.RecommendationsCardHorizontalTitleFirst,
});

RecommendationsCardHorizontalTitleFirst.preload();

const RecommendationsListWithoutContainer = loadable(() => import('./recomendations-list'), {
  resolveComponent: components => components.RecommendationsListWithoutContainer,
});

RecommendationsListWithoutContainer.preload();

const RecommendationsListGrid = loadable(() => import('./recomendations-list'), {
  resolveComponent: components => components.RecommendationsListGrid,
});

RecommendationsListGrid.preload();

const RecommendationsListDouble = loadable(() => import('./recomendations-list'), {
  resolveComponent: components => components.RecommendationsListDouble,
});

RecommendationsListDouble.preload();

const RecommendationsCarouselFreeWithoutContainer = loadable(() => import('./recomendations-carousel-free'), {
  resolveComponent: components => components.RecommendationsCarouselFreeWithoutContainer,
});

RecommendationsCarouselFreeWithoutContainer.preload();

const PolyCardTemplate = props => <Polycard polycard={props} className="recos-polycard" />;

const ContextTemplate = ({ contextValue, type, thousandSeparator, decimalSeparator, children }) => (
  <PolycardContext
    contextValue={{ ...contextValue, type }}
    thousandSeparator={thousandSeparator}
    decimalSeparator={decimalSeparator}
  >
    {children}
  </PolycardContext>
);

const Recommendations = ({ isPolycard, thousandSeparator, decimalSeparator, ...props }) => (
  <RecommendationsCarouselByType
    carousels={{
      grid: propsGrid => (
        <RecommendationsListWithoutContainer
          ListComponent={RecommendationsListGrid}
          listType="grid"
          Card={isPolycard ? PolyCardTemplate : RecommendationsCardVertical}
          {...propsGrid}
        />
      ),
      vertical: propsVertical => (
        <RecommendationsListWithoutContainer
          ListComponent={RecommendationsListDouble}
          listType="double"
          Card={isPolycard ? PolyCardTemplate : RecommendationsCardHorizontalTitleFirst}
          {...propsVertical}
        />
      ),
      horizontal: propsHorizontal => (
        <RecommendationsCarouselFreeWithoutContainer
          Card={isPolycard ? PolyCardTemplate : RecommendationsCardVertical}
          paddingHorizontal={16}
          {...propsHorizontal}
        />
      ),
      default: propsDefault => (
        <RecommendationsCarouselFreeWithoutContainer
          Card={isPolycard ? PolyCardTemplate : RecommendationsCardVertical}
          paddingHorizontal={16}
          {...propsDefault}
        />
      ),
    }}
    IconFactory={RecommendationsIconFactory}
    Context={ContextTemplate}
    contextProps={{
      type: 'grid-card',
      thousandSeparator,
      decimalSeparator,
    }}
    isPolycard={isPolycard}
    {...props}
  />
);

ContextTemplate.propTypes = {
  children: node,
  contextValue: shape({}),
  type: string,
  decimalSeparator: string.isRequired,
  thousandSeparator: string.isRequired,
};

Recommendations.propTypes = {
  isPolycard: bool.isRequired,
  decimalSeparator: string.isRequired,
  thousandSeparator: string.isRequired,
};

module.exports = Recommendations;
